import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Waypoint } from "react-waypoint";


import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { loadMore, selectPosts, selectLoader } from "./blogSlice";
import { CardAlpha } from "../../components/cards/CardAlpha";
import { Fade } from "../../components/animatons/Fade";

export function Blog() {
  const posts = useSelector(selectPosts);
  const loader = useSelector(selectLoader);
  const dispatch = useDispatch();

  const loadMoreHandler = () => {
    //console.log("load more... (%s)", posts?.length);
    if (!loader) {
      dispatch(loadMore(posts?.length));
    }
  };

  return (
    <Container>
      <hr />
      
      <Row>
        {posts &&
          posts.map((post) => {
            return (
              <Col sm={6} key={post.id}>
                <Fade>
                  <LinkContainer to={`/posts/${post.slug}`}>
                    <div>
                      <CardAlpha post={post} />
                    </div>
                  </LinkContainer>
                </Fade>
              </Col>
            );
          })}
      </Row>
      {loader && (
        <div>
          <br />
          <br />
          <br />
          <b>Loading more items...</b>
          <br />
          <br />
          <br />
        </div>
      )}

      <Waypoint onEnter={loadMoreHandler} />
    </Container>
  );
}
