import { createSlice } from "@reduxjs/toolkit";
import { getPostsList, getPostDetails } from "../../api/posts";

export const blogSlice = createSlice({
  name: "blog",
  initialState: {
    posts: [],
    loading: false,
    selectedPost: {}
  },
  reducers: {
    add: (state, action) => {
      //let number = state.posts.length + 1;
      state.posts = [
        ...state.posts,
        ...action.payload,
      ];
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    selectedPost: (state, action) => {
      state.selectedPost = action.payload;
    }
  },
});

export const { add, setLoader, selectedPost } = blogSlice.actions;

export const loadMore = (offset) => dispatch => {
  dispatch(setLoader(true));
  getPostsList(offset).then(result => {
    dispatch(add(result));
    dispatch(setLoader(false));
  })
};

export const getPostAsync = (id) => dispatch => {
  dispatch(setLoader(true));
  getPostDetails(id).then(result => {
    dispatch(selectedPost(result));
    dispatch(setLoader(false));
  })
};

export const selectPosts = (state) => state.blog.posts;
export const selectPost = (state) => state.blog.selectedPost;
export const selectLoader = (state) => state.blog.loading;

export default blogSlice.reducer;
