import React from "react";
import { useSpring, animated } from "react-spring";

export function Fade({ children }) {
  const contentProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, 50px)" },
    config: { duration: 250 },
  });
  return <animated.div style={contentProps}>{children}</animated.div>;
}
