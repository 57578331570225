import React from "react";
import { Link } from "react-scroll";
import { NavigationItem } from "./NavigationItem/NavigationItem";

import "./SideNavigation.css";

export function SideNavigation() {
  return (
    <div className="sideNavigation">
      <NavigationItem to="initial">
        TOP
      </NavigationItem>
      <NavigationItem to="test1">
        TEST 1
      </NavigationItem>
      <NavigationItem to="test2">
        TEST 2
      </NavigationItem>
      <NavigationItem to="test3">
        TEST 3
      </NavigationItem>
      <NavigationItem to="test4">
        TEST 4
      </NavigationItem>
      
    </div>
  );
}
