import React, { useEffect } from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

import "./Scroll.css";

export function Scroll() {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });
    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToWithContainer = () => {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
    );
  };

  return (
    <Container>
      <Navbar expand="lg" fixed="bottom" className="nav-links">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <ButtonGroup size="sm">
              <Button variant="light">
                <Link
                  activeClass="active"
                  className="link"
                  to="test1"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Test 1
                </Link>
              </Button>
              <Button variant="light">
                <Link
                  activeClass="active"
                  className="link"
                  to="test2"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Test 2
                </Link>
              </Button>
              <Button variant="light">
                <Link
                  activeClass="active"
                  className="link"
                  to="test3"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Test 3
                </Link>
              </Button>
              <Button variant="light">
                {" "}
                <Link
                  activeClass="active"
                  className="link"
                  to="test4"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Test 4
                </Link>{" "}
              </Button>
              <Button variant="light">
                {" "}
                <Link
                  activeClass="active"
                  className="link"
                  to="test5"
                  spy={true}
                  smooth={true}
                  duration={500}
                  delay={1000}
                >
                  Test 5 ( delay )
                </Link>{" "}
              </Button>
              <Button variant="light">
                {" "}
                <Link
                  activeClass="active"
                  className="link"
                  to="anchor"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Test 6 (anchor)
                </Link>
              </Button>
              <Button
                variant="light"
                className="link"
                onClick={() => scroll.scrollTo(100)}
              >
                Scroll To 100!
              </Button>
              <Button
                variant="light"
                className="link"
                onClick={() => scroll.scrollToBottom()}
              >
                Scroll To Bottom
              </Button>
              <Button
                variant="light"
                className="link"
                onClick={() => scroll.scrollMore(500)}
              >
                Scroll 500 More!
              </Button>
              <Button
                variant="light"
                className="link"
                onClick={() => scroll.scrollMore(1000, { delay: 1500 })}
              >
                Scroll 1000 More! ( delay )
              </Button>
              <Button variant="light">
                <Link
                  activeClass="active"
                  className="link"
                  to="same"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Same target
                </Link>
              </Button>
              <Button variant="light">
                {" "}
                <Link
                  activeClass="active"
                  className="link"
                  to="same"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Same target
                </Link>
              </Button>
              <Button variant="light" className="link" onClick={scrollTo}>
                Scroll to element
              </Button>
              <Button
                variant="light"
                className="link"
                onClick={scrollToWithContainer}
              >
                Scroll to element within container
              </Button>
            </ButtonGroup>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Element name="test1" className="element">
        test 1
      </Element>

      <Element name="test2" className="element">
        test 2
      </Element>

      <Element name="test3" className="element">
        test 3
      </Element>

      <Element name="test4" className="element">
        test 4
      </Element>

      <Element name="test5" className="element">
        test 5
      </Element>

      <div id="anchor" className="element">
        test 6 (anchor)
      </div>

      <Link
        activeClass="active"
        to="firstInsideContainer"
        spy={true}
        smooth={true}
        duration={250}
        containerId="containerElement"
        style={{ display: "inline-block", margin: "20px" }}
      >
        Go to first element inside container
      </Link>

      <Link
        activeClass="active"
        to="secondInsideContainer"
        spy={true}
        smooth={true}
        duration={250}
        containerId="containerElement"
        style={{ display: "inline-block", margin: "20px" }}
      >
        Go to second element inside container
      </Link>

      <Element
        name="test7"
        className="element"
        id="containerElement"
        style={{
          position: "relative",
          height: "200px",
          overflow: "scroll",
          marginBottom: "100px",
        }}
      >
        <Element
          name="firstInsideContainer"
          style={{
            marginBottom: "200px",
          }}
        >
          first element inside container
        </Element>

        <Element
          name="secondInsideContainer"
          style={{
            marginBottom: "200px",
          }}
        >
          second element inside container
        </Element>
      </Element>

      <Element id="same" className="element">
        Two links point to this
      </Element>

      <Element name="scroll-to-element" className="element">
        Scroll to element
      </Element>

      <Element
        className="element"
        id="scroll-container"
        style={{
          position: "relative",
          height: "200px",
          overflow: "scroll",
          marginBottom: "100px",
        }}
      >
        <Element
          name="scroll-container-first-element"
          style={{
            marginBottom: "200px",
          }}
        >
          first element inside container
        </Element>

        <Element
          name="scroll-container-second-element"
          style={{
            marginBottom: "200px",
          }}
        >
          second element inside container
        </Element>
      </Element>

      <Button
                variant="light"
                className="link"
                onClick={scrollToTop}
              >
                To the top!
              </Button>
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}
