import React, { useState } from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import "./Home.css";
import { useSpring, animated } from "react-spring";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Screen } from "../../components/Screen";
import { SideNavigation } from "../../components/SideNavigation/SideNavigation";
import { NavigationItem } from "../../components/SideNavigation/NavigationItem/NavigationItem";

export function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const menuSpring = useSpring({
    opacity: showMenu ? 1 : 0,
    config: { duration: 250 },
  });
  const showMenuHandler = () => {
    console.log("start");
    setShowMenu(true);
  };

  const hideMenuHandler = () => {
    console.log("end");
    setShowMenu(false);
  };
  return (
    <div>
      <animated.div style={menuSpring}><SideNavigation /></animated.div>
      <Screen name="initial" onWaypointEnter={hideMenuHandler}>
        <Container>
          <br />
          <br />
          <br />
          <Row>
            <Col sm={6}>
              <h1>HOME</h1>
            </Col>
            <Col sm={6}>
              <Row>
              <Col sm={4}>
              <NavigationItem to="test1">
        TEST 1
      </NavigationItem>
                </Col>
              <Col sm={4}>
              <NavigationItem to="test2">
        TEST 2
      </NavigationItem>
                </Col>
              <Col sm={4}>
              <NavigationItem to="test3">
        TEST 3
      </NavigationItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Screen>
      <Screen name="test1" onWaypointEnter={showMenuHandler}>
        <Container>
          <br />
          <br />
          <br />
          11111111111111111111111
        </Container>
      </Screen>
      <Screen name="test2" alt>
        <Container>
          <br />
          <br />
          <br />
          2222222222222222222
        </Container>
      </Screen>
      <Screen name="test3">
        {" "}
        <Container>
          <br />
          <br />
          <br />
          333333333333333333
        </Container>
      </Screen>
      <Screen name="test4" alt>
        <Container>
          <br />
          <br />
          <br />
          4444444444444444444444
        </Container>
      </Screen>
    </div>
  );
}
