import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from './template/Header';
import { Home } from "./features/home/Home";
import { Blog } from "./features/blog/Blog";
import { Post } from "./features/blog/single/Post";
import { Counter } from "./features/counter/Counter";
import { Scroll } from "./features/scroll/Scroll";
import { Spring } from "./features/spring/Spring";

function App() {
  return (
    <Router>
      <div>
        <Header title="WP-REACT" />
        <Switch>
          <Route path="/counter">
            <Counter />
          </Route>
          <Route path="/posts/:slug">
            <Post />
          </Route>
          <Route path="/posts">
            <Blog />
          </Route>
          <Route path="/scroll">
            <Scroll />
          </Route>
          <Route path="/spring">
            <Spring />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
