import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { selectPost, selectLoader, getPostAsync } from "../blogSlice";

export function Post() {
  const { slug } = useParams();
  const post = useSelector(selectPost);
  const loader = useSelector(selectLoader);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("slug: ", slug);
    if (post.slug !== slug) {
      dispatch(getPostAsync(slug));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <LinkContainer to={`/posts`}>
        <Button variant="link">Back</Button>
      </LinkContainer>
      {loader && (
        <b>
          Loading...
          <br />
        </b>
      )}
      <hr />
      {post && (
        <div>
          <p key={post.id}>{post.title?.rendered}</p>
          <div dangerouslySetInnerHTML={{__html: post.content?.rendered}}></div>
        </div>
      )}
    </Container>
  );
}
