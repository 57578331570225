import React from "react";
import { Waypoint } from "react-waypoint";
import {
  Element
} from "react-scroll";
import "./Screen.css";


export function Screen({name, children, alt, onWaypointEnter}) {
  return (
    <div className='screen-root'>
    <Element name={name} className={alt ? 'screen screen-alt' : 'screen'} >
      {children}
    </Element>
    <Waypoint onEnter={onWaypointEnter} />
    </div>
  );
}
