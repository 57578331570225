import React from "react";

import Card from "react-bootstrap/Card";

export function CardAlpha({ post }) {
  return (
    <Card>
      <Card.Img variant="top" src={post.thumbnail} />
      <Card.Body>
        <Card.Title><div dangerouslySetInnerHTML={{__html: post.title?.rendered}}></div></Card.Title>
        <Card.Text dangerouslySetInnerHTML={{__html: post.excerpt?.rendered}}></Card.Text>
      </Card.Body>
    </Card>
  );
}
