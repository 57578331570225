import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-scroll";

export function Header({ title }) {
  return (
    <Navbar expand="lg" className="justify-content-center" fixed="top">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>{title}</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={window.location.pathname}>
            <LinkContainer to="/posts">
              <Nav.Link>Blog</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/counter">
              <Nav.Link>Counter</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/scroll">
              <Nav.Link>Scroll</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/spring">
              <Nav.Link>Spring</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
