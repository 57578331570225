import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "react-scroll";

export function NavigationItem({ children, to }) {
  const [active, setActive] = useState(false);
  const contentProps = useSpring({
    //transform: active ? "scale(1.2)" : "scale(1)",
    height: active ? 120 : 100,
    width: active ? 120 : 100,
    config: { duration: 250 },
  });

  return (
    <animated.div style={contentProps} className="sideNavigation-item">
      <Link
        activeClass="active"
        
        to={to}
        spy={true}
        smooth={true}
        duration={500}
        onSetActive={() => setActive(true)}
        onSetInactive={() => setActive(false)}
      >
        {children}
      </Link>
    </animated.div>
  );
}
