import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Waypoint } from "react-waypoint";

import Container from "react-bootstrap/Container";

import "./Spring.css";

export function Spring() {
  const props2 = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [testMsg, setTestMsg] = useState(false);
  const contentProps = useSpring({
    opacity: testMsg ? 1 : 0,
    marginLeft: testMsg ? 0 : -500,
    config: { duration: 1000 }
  });

  const startAnim = () => {
    console.log("start");
    setTestMsg(true);
  };

  const endAnim = () => {
    console.log("end");
    setTestMsg(false);
  };

  return (
    <Container>
      <br />
      ...
      <br />
      <br />
      <animated.div style={props2}>I will fade in</animated.div>
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <animated.div style={contentProps}><h1>TEST</h1></animated.div>
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <br />
      ...
      <br />
      <br />
      <Waypoint onEnter={startAnim} onLeave={endAnim} />
    </Container>
  );
}
