import apiCore from "./api-core";

//
export async function getPostsList(offset, per_page) {
  console.log("getPosts, offset: %s", offset);
  const response = await apiCore.get("/posts", {
    params: { 
      per_page: per_page ? per_page : process.env.REACT_APP_WP_POSTS_PER_PAGE, 
      offset,
      //_fields: "id,slug,title,excerpt,link"
    },
  });
  console.log("response: %o", response);
  return response.data;
}


export async function getPostDetails(id) {
  console.log("getPost---------, id: %s", id);
  const response = await apiCore.get("/posts", {
    params: {
      slug: id,
    }
  });

  console.log("response-------------: %o", response);
  return response.data[0];
}